import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { SettingService } from '../../_services/setting.service';
import { GeneralService } from '../../_services/general.service';
import { PropertyService } from '../../_services/property.service';
import { AlertService } from '../../_services/alert.service';
import { DateTimeService } from '../../_services/datetime.service';


@Component({
  selector: 'app-templatedetail',
  templateUrl: './templatedetail.component.html'
})
export class TemplateDetailComponent implements OnInit {

  private _id: number = 0;
  private _template: any = {};
  private _weekdays: any[] = [];
  private _properties: any[] = [];
  private _newsetting: any = {};
  private _newbookingprofile: any = {};
  private _loading: boolean = false;
  private _iterations: any[] = [];
  private _timetypes: any[] = [];
  private _calcweekdays: any[] = [];
  private _midnightoptions: any[] = [];
  private _types: any[] = [];
  private _parents: any[] = [];
  private _parent: number = 0;
  private _parentname: string = '';

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public generalService: GeneralService,
    private dataService: DataService,
    private settingService: SettingService,
    private propertyService: PropertyService,
    private alertService: AlertService,
    private dateTimeService: DateTimeService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {
    //Reset
    this.settingService.initView();

    this.route.paramMap.subscribe(params => {
      this._id = Number(params.get('id'));
      this._parent = Number(params.get('parent'));
    });
  }


  ngOnInit() {

    this.init();
    this.manageload(this._parent > 0 ? this._parent : this._id);
  }


  //Properties
  public get id() {
    return this._id;
  }
  public get parent() {
    return this._parent;
  }
  public get template() {
    return this._template;
  }
  public get weekdays() {
    return this._weekdays;
  }
  public get properties() {
    return this._properties;
  }
  public get newsetting() {
    return this._newsetting;
  }
  public get isloading() {
    return this._loading;
  }
  public get iterations() {
    return this._iterations;
  }
  public get timetypes() {
    return this._timetypes;
  }
  public get calcweekdays() {
    return this._calcweekdays;
  }
  public get midnightoptions() {
    return this._midnightoptions;
  }
  public get types() {
    return this._types;
  }
  public get parents() {
    return this._parents;
  }



  //Methods
  public manageEnd(setting) {
    if (setting.MealBreak) {
      setting.Break = 0;
      setting.BreakStart = '0001-01-01T00:00:00';
    }
    const start = new Date(setting.Start);
    setting.End = new Date(start.setHours(start.getHours() + setting.Hours, start.getMinutes() + setting.Minutes + setting.Break));
  }

  public load() {
    this.manageload(this.id);
  }
  private manageload(templateId) {

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/bookingtemplates/' + templateId, 'GET', {})
      .subscribe((res) => {

        this._template = res;
        this._template.OffDuty = this.generalService.formatdecimal(this._template.OffDuty);
        this._template.Vacation = this.generalService.formatdecimal(this._template.Vacation);
        this._template.EmploymentRate = this.generalService.formatdecimal(this._template.EmploymentRate);

        if (this._template) {
          this._template.Settings.forEach((setting) => {
         
            //Properties
            setting.Properties.forEach((bookingproperty) => {
              let property = this.propertyService.getProperty(bookingproperty.PropertyId);
              if (property) {
                bookingproperty.Type = property.Type;
                bookingproperty.Items = property.Items;
              }
            });

          });

          //Parent
          if (this._parent > 0 && this._id == 0) {
            this._template.Id = 0;
            this._template.ParentId = this._parent;
            this._template.Name += ' - ' + this.languageService.getItem(1220);
          }
        }

        this._loading = false;
      });
  }
  public isTimeVisible(time) {
    return (time != '0001-01-01T00:00:00');
  }
  public addSetting(setting = null) {
    if (this._template && this._template.Settings) {
      if (setting != null) {
        const index = this._template.Settings.indexOf(setting);
        this._newsetting = {
          ...setting
        }
        this._newsetting.IsNew = true;
        this._newsetting.Id = 0;
        this._newsetting.Properties = this._newsetting.Properties.map(x => ({ ...x, Id: 0 }));
        this._newsetting.Activities = this._newsetting.Activities.map(x => ({ ...x, Id: 0 }));
        this._template.Settings.splice(index + 1, 0, this._newsetting);
      }
      else {
        this._template.Settings.push(this._newsetting);
      }
      this.createNewSetting();
    }
  }
  public deleteSetting(setting) {
    if (setting.Id > 0) {
      //Exists on server
      setting.IsDeleted = !setting.IsDeleted;
    }
    else {
      //Exists only on client => remove
      let index = this._template.Settings.indexOf(setting);
      if (index > -1) {
        this._template.Settings.splice(index, 1);
      }
    }
  }

  public manageActivityEnd(activity) {
    const start = new Date(activity.Start);
    const end = new Date(activity.End);
    const normalizedStart = new Date(1970, 0, 1, start.getHours(), start.getMinutes());
    let endDay = 1;
    if (end.getHours() /60 + end.getMinutes() < start.getHours() / 60 + start.getMinutes()) {
      endDay = 2;
    }
    const normalizedEnd = new Date(1970, 0, endDay, end.getHours(), end.getMinutes());
    const diffInMs = normalizedStart.getTime() -  normalizedEnd.getTime();
    activity.EndMinutes = Math.abs(Math.floor(diffInMs / 1000 / 60));
  }

  public addActivity(setting) {
    setting.Activities.push({ IsEdit: true });
  }

  public deleteActivity(setting, activity) {
    if (activity.Id) {
      activity.IsDeleted = !activity.IsDeleted;
    }
    else {
      let index = setting.Activities.indexOf(activity);
      if (index > -1) {
        setting.Activities.splice(index, 1);
      }
    }
  }

  public addBookingProfile(setting) {
    setting.Properties.push(this._newbookingprofile);
    this.propertyChosen(this._newbookingprofile.PropertyId, setting, setting.Properties.length - 1);

    this.createNewBookingProfile();
  }
  public deleteBookingProfile(setting, bookingprofile) {
    if (bookingprofile.Id > 0) {
      //Exists on server
      bookingprofile.IsDeleted = !bookingprofile.IsDeleted;
    }
    else {
      //Exists only on client => remove
      let index = setting.Properties.indexOf(bookingprofile);
      if (index > -1) {
        setting.Properties.splice(index, 1);
      }
    }
  }
  public connect() {

    this.router.navigate(['/admin/templates/0/parent/' + this._id], { replaceUrl: true }); 
  }
  public copy() {
    this.save(true);
  }
  public save(iscopy:boolean = false) {

    let verb = 'Post';
    let path = '/api/v1/bookingtemplates/'
    if (this._id > 0 && !iscopy) {
      verb = 'Put';
      path += this._id;
    }

    if (this._template && this._template.Settings) {
      let error = false;
      this._template.Settings.forEach((setting) => {
        setting.IsError = false;

        if (!error) {
          const dStart = new Date(setting.Start);
          const dBreak = new Date(setting.BreakStart);
          const compareBreak = new Date(1970, 1, 1, dBreak.getHours(), dBreak.getMinutes(), dBreak.getSeconds());
          const compareStart = new Date(1970, 1, 1, dStart.getHours(), dStart.getMinutes(), dStart.getSeconds());
          const compareEnd = new Date(1970, 1, 1, dStart.getHours() + setting.Hours, dStart.getMinutes() + setting.Minutes + setting.Break, dStart.getSeconds());
          const compareBreakEnd = new Date(1970, 1, 1, compareEnd.getHours(), compareEnd.getMinutes() - setting.Break, compareEnd.getSeconds());


          if (!Number.isInteger(setting.Hours)) {
            this.alertService.Add({ type: 'danger', message: this.languageService.getItem(1409).replace('{0}', this.languageService.getItem(624)) });
            setting.IsError = error = true;
            return;

          }
          if (!Number.isInteger(setting.Minutes)) {
            this.alertService.Add({ type: 'danger', message: this.languageService.getItem(1409).replace('{0}', this.languageService.getItem(623)) });
            setting.IsError = error = true;
            return;

          }
          if (!Number.isInteger(setting.Break)) {
            this.alertService.Add({ type: 'danger', message: this.languageService.getItem(1409).replace('{0}', this.languageService.getItem(1364)) });
            setting.IsError = error = true;
            return;
          }

          if (setting.Hour <= 0 && setting.Minutes <= 0) {
            this.alertService.Add({ type: 'danger', message: this.languageService.getItem(662) });
            setting.IsError = error = true;
            return;
          }
          if (compareStart > compareEnd) {
            this.alertService.Add({ type: 'danger', message: this.languageService.getItem(662) });
            setting.IsError = error = true;
            return;
          }
          if ((((setting.Hours * 60) + setting.Minutes) < 1440) && compareBreak < compareStart && compareBreak > compareBreakEnd)
          {
            this.alertService.Add({ type: 'danger', message: this.languageService.getItem(788) });
            setting.IsError = error = true;
            return;
          }
          if (setting.Max < setting.Quantity) {
            this.alertService.Add({ type: 'danger', message: this.languageService.getItem(1022) });
            setting.IsError = error = true;
            return;
          }
          if (setting.Min > setting.Quantity) {
            this.alertService.Add({ type: 'danger', message: this.languageService.getItem(1023) });
            setting.IsError = error = true;
            return;
          }

          if (!setting.Weekdays || setting.Weekdays == 0) {
            setting.Weekdays = 127 //All days
          }
          if (!setting.Iteration || setting.Iteration == 0) {
            setting.Iteration = 1 //Every week
          }

          if (!setting.Activity || setting.Activity.length == 0) {
            setting.Activity = 0;
          }
          if (!setting.TimeType || setting.TimeType.length == 0) {
            setting.TimeType = 0;
          }
        }
      });
      if (this._template.Name.length > 50) {
        this.alertService.Add({ type: 'danger', message: this.languageService.getItem(732).replace('{0}', this.languageService.getItem(509)).replace('{1}', '50') });
        return;
      }
      if (error) {
        return;
      }
    }

    this.dataService.tokenRequest(path, verb, this._template, 'text', 'response')
      .subscribe((response) => {

        if (response) {
          
          this.alertService.Add({ type: 'success', message: response.body });

          if (response.status == 201) {
            this.router.navigate([response.headers.get('Location')], { replaceUrl: true })
              .finally(() => {
                this.load();
            });
          }
          else {
            this.load();
          }
        }

      });

  }
  public delete() {

    this.dataService.tokenRequest('/api/v1/bookingtemplates/' + this._id, 'Delete', {}, 'text')
      .subscribe((res) => {

        this.alertService.Add({ type: 'success', message: res });

        this.location.back();
      });
  }
  public propertyChosen(e, setting, idxBookingProfile) {

    let property = this.propertyService.getProperty(e);
    if (property) {
      let bookingprofile = setting.Properties[idxBookingProfile];
      if (bookingprofile) {
        bookingprofile.Type = property.Type;
        bookingprofile.Items = property.Items;
        if (bookingprofile.Type == 'System.DateTime') {
          bookingprofile.Value = this.dateTimeService.formatWithTimeZone(new Date());
        }
        else if (bookingprofile.Type == 'System.Date') {
          let now = new Date();
          bookingprofile.Value = this.dateTimeService.formatWithTimeZone(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0));
        }
        else if (bookingprofile.Type == 'System.Time') {
          bookingprofile.Value = this.dateTimeService.formatWithTimeZone(new Date());
        }
        else {
          bookingprofile.Value = '';
        }
      }
    }
  }
  public manageReload(filteritem) {
    if (filteritem.ReloadItems) {
      let property = this.propertyService.getProperty(filteritem.Id);
      if (property) {
        filteritem.Items = property.Items;
      }
      filteritem.ReloadItems = false;
    }
    else {
      return filteritem.Items;
    }
  }





  //Functions
  private init() {
    this.createNewSetting();

    //Calc Weekdays
    this._calcweekdays = [
      { Id: 1, Name: this.languageService.getItem(209) },
      { Id: 2, Name: this.languageService.getItem(210) },
      { Id: 3, Name: this.languageService.getItem(211) },
      { Id: 4, Name: this.languageService.getItem(212) },
      { Id: 5, Name: this.languageService.getItem(213) },
      { Id: 6, Name: this.languageService.getItem(214) },
      { Id: 0, Name: this.languageService.getItem(215) }
    ];
    //Calc Weekdays
    this._midnightoptions = [
      { Id: 1, Name: 'First' },
      { Id: 2, Name: 'Last' },
      { Id: 3, Name: 'Biggest' }
    ];

    this._types = [
      { Id: 1, Name: this.languageService.getItem(1385) },
      { Id: 2, Name: this.languageService.getItem(1400) }
    ];

    this._parents = [];
    this._parentname = '';
    this.dataService.tokenRequest('/api/v1/bookingtemplates', 'GET', {})
      .subscribe((res) => {
        res.forEach((parent) => {
          if (parent.Id != this._id) {
            this._parents.push(parent);
          }
          if (parent.Id == this._parent) {
            this._parentname = parent.Name;
          }
        });
      });

    this._weekdays = [
      { Id: 2, Name: this.languageService.getItem(209).substring(0,2) },
      { Id: 4, Name: this.languageService.getItem(210).substring(0, 2) },
      { Id: 8, Name: this.languageService.getItem(211).substring(0, 2) },
      { Id: 16, Name: this.languageService.getItem(212).substring(0, 2) },
      { Id: 32, Name: this.languageService.getItem(213).substring(0, 2) },
      { Id: 64, Name: this.languageService.getItem(214).substring(0, 2) },
      { Id: 1, Name: this.languageService.getItem(215).substring(0, 2) }
    ];

    this._iterations = [
      { Id: 0, Name: '' },
      { Id: 1, Name: this.languageService.getItem(363) },
      { Id: 2, Name: this.languageService.getItem(364) },
      { Id: 3, Name: this.languageService.getItem(365) },
      { Id: 4, Name: this.languageService.getItem(366) },
      { Id: 5, Name: this.languageService.getItem(367) },
      { Id: 6, Name: this.languageService.getItem(368) },
      { Id: 7, Name: this.languageService.getItem(369) },
      { Id: 8, Name: this.languageService.getItem(370) },
      { Id: 9, Name: this.languageService.getItem(371) },
      { Id: 10, Name: this.languageService.getItem(372) }
    ];

    //TimeTypes
    this._timetypes = [];
    this.generalService.timetypes.forEach((timetype) => {

      this._timetypes.push({ Id: timetype.Key, Name: timetype.Value });

    });

    this.dataService.tokenRequest('/api/v1/properties/type/bookingprofile', 'GET', {})
      .subscribe((list) => {
        this._properties = list;

        this.createNewBookingProfile();
      });

  }

  private createNewSetting() {
    this._newsetting = {
      WeekNr: 0,
      Quantity: 0,
      Min: 0,
      Max: 0,
      Hours: 0,
      Minutes: 0,
      Break: 0,
      IsActive: true,
      IsNew: true,
      IsEdit: true,
      Properties: [],
      Activities: [],
    };
  }
  private createNewBookingProfile() {

    let propertyId = 0;
    if (this._properties.length > 0) {
      propertyId = this._properties[0].Id;
    }

    this._newbookingprofile = {
      PropertyId: propertyId,
      IsEdit: true
    };
  }

}
