import { Component, Inject, Injectable, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild, AfterViewInit, OnDestroy, input, SimpleChanges } from "@angular/core";
import { LanguageService } from "../_services/language.service";
import { DOCUMENT } from '@angular/common';
import Quill from 'quill';
import { PermissionService } from "../_services/permission.service";
import Toolbar from "quill/modules/toolbar";
import { AlertService } from "../_services/alert.service";
import { Delta } from "quill/core";

@Component({
  selector: 'swe-richtexteditor',
  templateUrl: './richtexteditor.component.html',
})
export class RichTextEditorComponent implements AfterViewInit, OnDestroy, OnChanges {
  private quillEditor: Quill | null = null;
  @ViewChild('editorContainer', { static: true }) editorContainer: ElementRef;
  @ViewChild('videoPopup', { static: true }) videoPopup: ElementRef;
  @ViewChild('videoInput', { static: true }) videoInput: ElementRef;
  @ViewChild('imagePopup', { static: true }) imagePopup: ElementRef;
  @ViewChild('imageInput', { static: true }) imageInput: ElementRef;

  @Input() model: string;
  @Input() label: string = '';
  @Input() statusLabel: number = 0; //0 = Hide, 1 = Standard, 2 = Grouped, 3 = Inline
  @Input() displayname: string = '';
  @Input() items: any[];
  @Input() disabled: boolean = false;
  @Input() container: any = {};
  @Output() modelChange: EventEmitter<string> = new EventEmitter<string>();

  private _videoUrl: string = '';
  private _imageUrl: string = '';
  private _range: any;
  constructor(private permissionService: PermissionService, private alertService: AlertService, public languageService: LanguageService) { }

  public get videoUrl() {
    return this._videoUrl;
  }
  public set videoUrl(value) {
    this._videoUrl = value;
  }

  
  public get imageUrl() {
    return this._imageUrl;
  }
  public set imageUrl(value) {
    this._imageUrl = value;
  }


  public get cols(): number {

    if (this.statusLabel == 1) {
      //Standard
      return this.permissionService.permissions.ProfileLabel;
    }
    else if (this.statusLabel == 2) {
      //Grouped
      return 0;
    }
    else if (this.statusLabel == 3) {
      //Inline
      return 3;
    }

    return 0;
  }

  ngAfterViewInit(): void {
    let toolbar = this.permissionService.user.IsSuper ?
      [
        [{ 'header': [1, 2, 3, 4, false] }],
        ['bold', 'italic', 'video', 'image']
      ]
      :
      [
        [{ 'header': [1, 2, 3, 4, false] }],
        ['bold', 'italic', 'video']
      ];

    this.quillEditor = new Quill(this.editorContainer.nativeElement, {
      theme: 'snow',
      modules: {
        toolbar: this.container.toolbar ? this.container.toolbar : toolbar
      },
    });
    if (this.permissionService.user.IsSuper) {
      (this.quillEditor.getModule("toolbar") as Toolbar).addHandler("video", this.videoHandler.bind(this));
    }
    (this.quillEditor.getModule("toolbar") as Toolbar).addHandler("image", this.imageHandler.bind(this));

    this.quillEditor.on('text-change', () => {
      this.model = this.quillEditor?.root.innerHTML || '';
      this.modelChange.emit(this.model);
    });

    if (this.model) {
      const delta = this.quillEditor.clipboard.convert({ html: this.model })

      this.quillEditor.setContents(delta, 'silent')
    }
    this.quillEditor.clipboard.addMatcher(Node.ELEMENT_NODE, function (node: any, delta) {
      return new Delta().insert(node.innerText);
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model && this.quillEditor) {
      const newValue = changes.model.currentValue;
      if (this.quillEditor.root.innerHTML !== newValue) {
        this.quillEditor.root.innerHTML = newValue;
      }
    }
    if (changes.disabled && this.quillEditor) {
      this.quillEditor.enable(!this.disabled);
    }
  }

  ngOnDestroy() {
    if (this.quillEditor) {
      this.quillEditor = null;
    }
  }

  public getLabelClass() {
    if (this.cols == 0) {
      return "";
    }
    return 'col-form-label col-' + this.cols;
  }


  public videoHandler() {
    if (this.videoPopup) {
      this._range = this.quillEditor.getSelection();
      this.videoPopup.nativeElement.style.display = 'block';
      this.videoInput.nativeElement.focus();
    }
  }

  public modalKeyDown(event, modal: ModalType) {
    if (event.key === 'Enter') {
      this.insert(modal);
    }
    if (event.key === 'Escape') {
      this._videoUrl = '';
      this.closeModal(modal);
    }
    return;
  }

  public closeModal(modal: ModalType) {
    if (modal == ModalType.video && this.videoPopup) {
      this.videoPopup.nativeElement.style.display = 'none';
      return;
    }
    else if (modal == ModalType.image && this.imagePopup) {
      this.imagePopup.nativeElement.style.display = 'none';
      return;
    }
  }

  private getUrl(modal: ModalType) {
    if (modal == ModalType.video) {
      let match = this._videoUrl.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/) ||
        this._videoUrl.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/) ||
        this._videoUrl.match(/^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/);
      this._videoUrl = '';
      if (match && match[2].length === 11) {
        return ('https') + '://www.youtube.com/embed/' + match[2] + '?showinfo=0';
      }

      return null;
    }
    if (modal == ModalType.image) {
      let match = this._imageUrl.match(/^https?:[/|.|\w|\s|-][^\s]*\.(?:jpg|gif|png)/);
      if (match) {
        return match[0];
      }
      return null;
    }

    return null;
  }

  private insert(modal: ModalType) {
    const url = this.getUrl(modal);
    console.log(url)
    if (!url) {
      this.alertService.Add({ type: 'warning', message: this.languageService.getItem(1412) });
      return;
    }

    if (url && this._range) {
      this.quillEditor.insertEmbed(this._range.index, ModalType[modal], url, Quill.sources.USER);
    }
    this.closeModal(modal);

  }


  public imageHandler() {
    if (this.imagePopup) {
      this._range = this.quillEditor.getSelection();
      this.imagePopup.nativeElement.style.display = 'block';
      this.imageInput.nativeElement.focus();
    }
  }

}
 enum ModalType {
    video = 0,
    image = 1
  }
